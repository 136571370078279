import React, { useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext";
import PageHeader from "./PageHeader";
import scroll from "../js/scroll";

const Press = () => {
  const { getPress, press } = useContext(MainContext);
  useEffect(() => {
    getPress();
    scroll();
  }, [getPress]);

  return (
    <div className="press">
      <div className="press__inner wrapper">
        <PageHeader title="Press" />
        <div className="press__content">
          <div className="press__list">
            {press.map(({ title, text, image, url, source }) => {
              return (
                <div className="review move-up" key={title}>
                  <div className="review__left">
                    <img className="review__image" alt={title} src={image} />
                  </div>
                  <div className="review__right">
                    <h2 className="review__title">{title}</h2>
                    <div className="review__text">{text}</div>
                    {!!source && (
                      <a href={url} target="_blank" rel="noreferrer" className="review__source">
                        {source}
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Press;
