import React, { useContext, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import PageHeader from './PageHeader'
import Composition from './Composition'
import scroll, { scrollToCategory } from '../js/scroll'

const Works = () => {
  const { getWorks, works } = useContext(MainContext)
  useEffect(() => {
    window.scrollTo(0, 0)
    getWorks()
    scroll()

    return () => {
      window.scrollTo(0, 0)
    }
  }, [getWorks])

  const handleMenuItemClick = (category) => {
    scrollToCategory(category) // Call the new scroll function
  }

  return (
    <div className="works">
      <div className="works__inner wrapper">
        <PageHeader title="Works" />
        <div className="works__content">
          <ul className="works__menu">
            {works.map(({ category }) => (
              <li
                key={category}
                onClick={() => handleMenuItemClick(category)}
                className="works__menu-item"
              >
                {category}
              </li>
            ))}
          </ul>
          <div className="works__list">
            {works.map(({ category, compositions }) => {
              return (
                <div className="work move-up" key={category}>
                  <div className="work__right">
                    <h2 className="work__title" id={`category-${category}`}>
                      {category}
                    </h2>
                    {compositions.map((composition) => (
                      <Composition composition={composition} />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Works
