import React, { useState, useRef } from 'react'

const Composition = ({ composition }) => {
  const [isRecordingShown, setIsRecordingShown] = useState(false)
  const [setIsAudioPlaying, isAudioPlaying] = useState(false)

  const { title, notes, audio_file, youtube, sample_pdf } = composition

  const myRef = useRef(null)

  const startAudio = () => {
    myRef.current.play()
    isAudioPlaying(true)
  }

  const pauseAudio = () => {
    myRef.current.pause()
    isAudioPlaying(false)
  }

  return (
    <div className="composition" key={title}>
      {title && <h2 className="composition__title">{title}</h2>}
      {notes && (
        <div
          className="composition__notes"
          dangerouslySetInnerHTML={{
            __html: notes,
          }}
        ></div>
      )}

      {(youtube || audio_file) && (
        <button
          className="composition__show"
          onClick={() => setIsRecordingShown((prev) => !prev)}
        >
          {isRecordingShown ? 'Hide' : 'Recording'}
        </button>
      )}
      {youtube && isRecordingShown && (
        <div className="composition__youtube">
          <div
            className="composition__video-frame"
            dangerouslySetInnerHTML={{
              __html: youtube,
            }}
          ></div>
        </div>
      )}

      {audio_file && isRecordingShown && (
        <div className="composition__video-file">
          <audio ref={myRef} src={audio_file.url} />
          <span className="composition__audio-label">{audio_file?.title}</span>
          {setIsAudioPlaying ? (
            <button className="composition__play" onClick={pauseAudio}>
              <i className="fas fa-pause" />
            </button>
          ) : (
            <button className="composition__play" onClick={startAudio}>
              <i className="fas fa-play" />
            </button>
          )}
        </div>
      )}
      {sample_pdf && (
        <div className="composition__sample">
          <a href={sample_pdf.url} target="_blank">
            Download Sample (PDF)
            <i className="external fas fa-external-link-alt" />
          </a>
        </div>
      )}
    </div>
  )
}

export default Composition
