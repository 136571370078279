import React, { createContext, useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

export const MainContext = createContext()

const MainContextProvider = (props) => {
  const [options, setOptions] = useState({})
  const [about, setAbout] = useState([])
  const [sierra, setSierra] = useState([])
  const [videos, setVideos] = useState([])
  const [audios, setAudios] = useState([])
  const [press, setPress] = useState([])
  const [works, setWorks] = useState([])
  const [calendar, setCalendar] = useState([])
  let url

  useEffect(() => {}, [videos, calendar, about, sierra, options])

  // window.location.href.indexOf("http://localhost") > -1
  //   ? (url = "https://ovalles")
  //   : (url = "https://alfredoovalles.com");

  url = 'https://alfredoovalles.com'

  const getOptions = useCallback(async () => {
    const result = await axios.get(
      `${url}/wp-json/acf/v3/options/acf-general-options`
    )
    setOptions({ ...result.data.acf })
  }, [setOptions, url])

  const getAbout = useCallback(async () => {
    const result = await axios(`${url}/wp-json/wp/v2/pages/?slug=about`)
    setAbout(result.data)
  }, [setAbout, url])

  const getSierra = useCallback(async () => {
    const result = await axios(
      `${url}/wp-json/wp/v2/pages/?slug=roberto-sierra`
    )

    setSierra(result.data)
  }, [setSierra, url])

  const getVideos = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/acf-videos`)
    let vids = result.data.acf.videos_list
    setVideos([...vids])
  }, [setVideos, url])

  const getAudios = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/acf-audio`)
    let auds = result.data.acf.audio_list
    setAudios([...auds])
  }, [setAudios, url])

  const getPress = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/acf-press`)
    let pressData = result.data.acf.press_list
    setPress([...pressData])
  }, [setPress, url])

  const getWorks = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/acf-works`)
    let worksData = result.data.acf.works_list
    setWorks([...worksData])
  }, [setWorks, url])

  const getCalendar = useCallback(async () => {
    const result = await axios(`${url}/wp-json/acf/v3/options/acf-calendar`)
    let cals = result.data.acf.calendar_list
    setCalendar([...cals])
  }, [setCalendar, url])

  return (
    <MainContext.Provider
      value={{
        getOptions,
        getAbout,
        getSierra,
        getVideos,
        getAudios,
        getPress,
        getWorks,
        getCalendar,
        options,
        about,
        sierra,
        videos,
        press,
        works,
        audios,
        calendar,
      }}
    >
      {props.children}
    </MainContext.Provider>
  )
}

export default withRouter(MainContextProvider)
