import React, { useContext, useEffect, useState } from "react";
import c from "classnames";
import { MainContext } from "../contexts/MainContext";
import PageHeader from "./PageHeader";

const About = () => {
  const [readMore, setReadMore] = useState(false);
  const { getAbout, about } = useContext(MainContext);

  useEffect(() => {
    getAbout();
  }, [getAbout]);

  return (
    <div className="about">
      {console.log("about", about)}
      <div className="about__inner wrapper">
        <PageHeader title="About" />
        <div className="about__content">
          <div
            className={c([
              "about__text",
              { "about__text--enhanced": readMore },
            ])}
          >
            {about.map((item) => (
              <div
                className="entry"
                key={item.id}
                style={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: item.content.rendered }}
              ></div>
            ))}
          </div>
          {!readMore && (
            <button
              className="more"
              role="button"
              onClick={() => setReadMore(true)}
            >
              Read more
            </button>
          )}
        </div>
        <img
          className="about__back"
          src="img/about.jpg"
          alt="Alfredo Ovalles"
        />
      </div>
    </div>
  );
};

export default About;
