import React, { useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext";
import PageHeader from "./PageHeader";

const Sierra = () => {
  const { getSierra, sierra } = useContext(MainContext);

  useEffect(() => {
    getSierra();
  }, [getSierra]);

  return (
    <div className="sierra">
      <div className="sierra__inner wrapper">
        <PageHeader title="Roberto Sierra" />
        <div className="sierra__content">
          <div className="sierra__text">
            {sierra.map((item) => (
              <div
                className="entry"
                key={item.id}
                style={{ color: "white", maxWidth: "700px" }}
                dangerouslySetInnerHTML={{ __html: item.content.rendered }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sierra;
